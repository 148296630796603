export const EnumTypeClinic = {
    dentistry: 1,
    psychology: 2,
    aesthetics: 3,
    barbershop: 4,
    physiotherapy: 5,
    pet: 6,
    beautysalon: 7,
    others: 8
}

export const EnumTypeClinicName = {
    1: "Odontologia",
    2: "Psicologia",
    3: "Estética",
    4: "Barbearia",
    5: "Fisioterapia",
    6: "Pet",
    7: "Salão de Beleza",
    8: "Outros"
}