import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

import { Router } from "react-router-dom";
import Routes from "./Routes";
import "./Vendor";
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import { history } from "./history";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import jwtDecode from "jwt-decode";

class App extends Component {
  onServiceWorkerUpdate = (registration) => {
    if (registration && registration.waiting) this.refreshAction(registration);
  };

  updateServiceWorker = (registration) => {
    const waitingWorker = registration && registration.waiting;
    if (waitingWorker) {
      waitingWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          console.log("reloaded update SW");
          this.forceUpdate();
          window.location.href = process.env.HOST;
          // window.location.reload()
        }
      });
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
    }
  };

  // componentDidMount = async () => {

  //   if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  //     serviceWorkerRegistration.register({
  //       onUpdate: this.onServiceWorkerUpdate
  //     });
  //   };
  // }

  componentDidMount = async () => {
    if (
      process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "staging"
    ) {
      serviceWorkerRegistration.register({
        onUpdate: this.onServiceWorkerUpdate,
      });
      // serviceWorkerRegistration.register({
      //   refreshAction: this.refreshAction, // Pass refreshAction here
      // });
    }

    // Listen for route changes and trigger update check
    history.listen(() => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (registration) {
            registration.update();
          }
        });
      }
    });
  };

  refreshAction = (registration) => {
    localStorage.clear();
    sessionStorage.clear();
    this.updateServiceWorker(registration);
    console.log("teste refreshAction");

    // Redirect to /login after confirming the update
    // Redirect to the full login URL
    // setTimeout(() => {
    //   window.location.href = `${window.location.origin}/login`;
    //   // navigate("/login");
    // }, 300); // Small delay to let the service worker update finish
  };

render() {
      // axios.defaults.timeout = 30000
      let isOpenCheck = false;

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response) {
                // Se o erro for 401 e nao estiver na pagina de login, redireciona para a tela de login
                if (error.response.status === 401 && window.location.pathname !== "/login") {
                    history.push("/");
                }
            } else {
                // Se nao houver resposta, indica que houve desconexao com a API
                if (!isOpenCheck) {
                    isOpenCheck = true;
                    toast.warn("Falha na Conexão. Favor entrar em contato com o suporte!", {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        onClose: () => (isOpenCheck = false),
                    });
                }
            }
            return Promise.reject(error);
        }
    ); 
 
    axios.interceptors.request.use(
      async (config) => {
        let app_token = JSON.parse(localStorage.getItem("app-token"));
        if (app_token != null) {
          if (jwtDecode(app_token.token).exp < Date.now() / 1000) {
            localStorage.clear();
            sessionStorage.clear();
            history.push("/login");
          }
        }
        let items = JSON.parse(localStorage.getItem("ClinicData"));
        let id = items != null ? parseInt(items.id) : 0;

        // if (config.url.endsWith('api/clinic')) {
        //   //passa o id do usuário para alterações em dados da clínica
        //   items = JSON.parse(localStorage.getItem('app-token')) ?? ''
        //   console.log(items,"do clinic");

        //   id = parseInt(items.id)
        // }
        //acesso como provider
        let tenantid =
          config.params != undefined ? config.params["tenantid"] ?? "" : "";

        config.headers = {
          Authorization: app_token != null ? `Bearer ${app_token.token}` : "",
          tenantid: tenantid != "" ? tenantid : id,
        };

        return config;
      },
      (error) => {
        // I cand handle a request with errors here
        return Promise.reject(error);
      }
    );

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */

    const basename =
      process.env.NODE_ENV === "development" ? "/" : PUBLIC_URL || "/";

    return (
      <Router basename={basename} history={history}>
        <Routes />
      </Router>
    );
  }
}
export default App;
