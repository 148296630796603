import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect, Router } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";
import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
// import BaseHorizontal from './components/Layout/BaseHorizontal';
import PrivateRoute from "./PrivateRoute";
import { history } from "./history";
/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const DashboardV1 = lazy(() => import("./components/Dashboard/DashboardV1"));
const Reports = lazy(() => import("./components/Dashboard/ReportsView"));
const AbsentClientReportPage = lazy(() =>
  import("./components/ExtraReports/AbsentClientReportPage")
);
const BirthReportPage = lazy(() =>
  import("./components/ExtraReports/BirthReportPage")
);
const DashboardV2 = lazy(() => import("./components/Dashboard/DashboardV2"));
const DashboardV3 = lazy(() => import("./components/Dashboard/DashboardV3"));

const ChartFlot = lazy(() => import("./components/Charts/ChartFlot"));
const ChartRadial = lazy(() => import("./components/Charts/ChartRadial"));
const ChartChartJS = lazy(() => import("./components/Charts/ChartChartJS"));
const ChartMorris = lazy(() => import("./components/Charts/ChartMorris"));
const ChartChartist = lazy(() => import("./components/Charts/ChartChartist"));

const AnamnesisRegistration = lazy(() =>
  import("./components/Registrations/AnamnesisRegistration")
);

const SellPackage = lazy(() =>
  import("./components/Operations/PackageProcedure/SellPackage")
);
const SellProduct = lazy(() =>
  import("./components/Operations/Stock/SellProduct")
);
const SellSubscriptionPlan = lazy(() =>
  import("./components/Operations/SubscriptionPlan/SellSubscriptionPlan")
);

const Calendar = lazy(() => import("./components/Calendar/Calendar"));

const ReceiptIssuance = lazy(() =>
  import("./components/Receipt/ReceiptIssuance")
);

const AttestedModel = lazy(() => import("./components/Attested/AttestedModel"));
const AttestedEmission = lazy(() =>
  import("./components/Attested/AttestedEmission")
);

const Treatment = lazy(() => import("./components/Treatment/TreatmentView"));
const SearchTreatments = lazy(() =>
  import("./components/SearchTreatments/SearchTreatments")
);

const SurveySettings = lazy(() => import("./components/Survey/SurveySettings"));
const SurveyResults = lazy(() => import("./components/Survey/SurveyResults"));

const OfficeRegister = lazy(() =>
  import("./components/Registrations/OfficeRegister")
);
const RedirectTabs = lazy(() =>
  import("./components/Registrations/Client/Register/RedirectTabs")
);
const DoctorRegister = lazy(() =>
  import("./components/Registrations/ProfessionalRegister")
);
const PackageRegister = lazy(() =>
  import("./components/Registrations/PackageRegister")
);
const SubscriptionPlanRegister = lazy(() =>
  import("./components/Registrations/SubscriptionPlanRegister")
);
const HealthPlanRegister = lazy(() =>
  import("./components/Registrations/HealthPlanRegister")
);
const EmployeeRegister = lazy(() =>
  import("./components/Registrations/EmployeeRegister")
);
const ProfilesRegister = lazy(() =>
  import("./components/Registrations/Profiles/ProfilesRegister")
);
const CostCenterRegister = lazy(() =>
  import("./components/Registrations/CostCenterRegister")
);
const UserRegister = lazy(() =>
  import("./components/Registrations/UserRegister")
);
const ProcedureRegister = lazy(() =>
  import("./components/Registrations/ProcedureRegister")
);
const GroupProcedureRegister = lazy(() =>
  import("./components/Registrations/GroupProcedureRegister")
);
const ProductRegister = lazy(() =>
  import("./components/Registrations/ProductRegister")
);
const ProviderRegister = lazy(() =>
  import("./components/Registrations/ProviderRegister")
);
const MedicamentsRegister = lazy(() =>
  import("./components/Registrations/MedicamentsRegister")
);
const RoomsRegister = lazy(() =>
  import("./components/Registrations/RoomsRegister")
);
const RatingsRegister = lazy(() =>
  import("./components/Registrations/RatingsRegister")
);
const PrescriptionsRegister = lazy(() =>
  import("./components/Operations/Prescription/PrescriptionsRegister")
);
const FinancialAccountRegister = lazy(() =>
  import("./components/Registrations/FinancialAccountRegister")
);
const PromotionRegister = lazy(() =>
  import("./components/Registrations/PromotionRegister")
);
const ConfigurationsRegister = lazy(() =>
  import("./components/Registrations/ConfigurationsRegister")
);
const SalesPageConfig = lazy(() =>
  import("./components/Pages/SalesPageConfig")
);
const LinkBundlerRegister = lazy(() =>
  import("./components/Registrations/LinkBundlerRegister")
);
const LinkSchedulingRegister = lazy(() =>
  import("./components/Registrations/LinkSchedulingRegister")
);
const GatewayOfPayments = lazy(() =>
  import("./components/Registrations/GatewayOfPaymentsRegister")
);
const CovenantRegister = lazy(() =>
  import("./components/Registrations/CovenantRegister")
);
const ConfigNFSe = lazy(() =>
  import("./components/Registrations/ConfigNFSeRegister")
);
const ProcedureHofToxineRegister = lazy(() =>
  import("./components/Registrations/ProcedureHofToxineRegister")
);
const ProcedureHofRegister = lazy(() =>
  import("./components/Registrations/ProcedureHofRegister")
);
const FormOfPaymentRegistration = lazy(() =>
  import("./components/Registrations/FormOfPaymentRegistration")
);

const FinancialOperation = lazy(() =>
  import("./components/Operations/Financial/FinancialOperation")
);
const StockOperation = lazy(() =>
  import("./components/Operations/Stock/StockOperation")
);
const Nfse = lazy(() => import("./components/Operations/Nfse/Nfse"));
const DadosNfse = lazy(() => import("./components/Operations/Nfse/DadosNfse"));
const PreScheduling = lazy(() =>
  import("./components/PreScheduling/PreScheduling")
);
const FinancialSummary = lazy(() =>
  import("./components/Operations/Financial/FinancialSummary")
);

const CentralAtendimento = lazy(() =>
  import("./components/CustomerService/CentralAtendimento")
);
const PerguntasFrequentes = lazy(() =>
  import("./components/CustomerService/PerguntasFrequentes")
);
const MeuPlano = lazy(() => import("./components/CustomerService/MeuPlano"));

const MapsGoogle = lazy(() => import("./components/Maps/MapsGoogle"));
const MapsVector = lazy(() => import("./components/Maps/MapsVector"));

const FormStandard = lazy(() => import("./components/Forms/FormStandard"));
const FormExtended = lazy(() => import("./components/Forms/FormExtended"));
const FormValidation = lazy(() => import("./components/Forms/FormValidation"));
const FormWizard = lazy(() => import("./components/Forms/FormWizard"));
const FormUpload = lazy(() => import("./components/Forms/FormUpload"));
const FormCropper = lazy(() => import("./components/Forms/FormCropper"));

const Login = lazy(() => import("./components/Pages/Login"));
const Register = lazy(() => import("./components/Pages/Register"));
const RegisterOne = lazy(() => import("./components/Pages/RegisterOne"));
const Recover = lazy(() => import("./components/Pages/Recover"));
const Lock = lazy(() => import("./components/Pages/Lock"));
const NotFound = lazy(() => import("./components/Pages/NotFound"));
const Error500 = lazy(() => import("./components/Pages/Error500"));
const Maintenance = lazy(() => import("./components/Pages/Maintenance"));
const Unsubscribe = lazy(() => import("./components/Pages/Unsubscribe"));
const PaymentWizard = lazy(() =>
  import("./components/PaymentWizard/PaymentWizard")
);
const SalesPage = lazy(() => import("./components/Pages/SalesPage"));
const CelcashFinancialTransaction = lazy(() =>
  import(
    "./components/Operations/Financial/CustomersCelcashTransaction/CelcashFinancialTransaction"
  )
);
const ResetPassword = lazy(() => import("./components/Pages/ResetPassword"));
const FontIcons = lazy(() => import("./components/Elements/FontIcons"));

const ConsultationAppointments = lazy(() =>
  import("./components/Reports/Appointments/ConsultationAppointments")
);
const ProfessionalFinances = lazy(() =>
  import("./components/Reports/Professional/ProfessionalFinances")
);
const SubscriptionPlanComissionFinances = lazy(() =>
  import(
    "./components/Reports/SubscriptionPlan/SubscriptonPlanComissionFinances"
  )
);
const FormOfPaymentsReport = lazy(() =>
  import("./components/Reports/Appointments/FormOfPaymentsReport")
);
const Reportfinancialaccount = lazy(() =>
  import("./components/Reports/financialAccount/ReportFinancialAccount")
);
const TreatmentReport = lazy(() =>
  import("./components/Reports/Treatments/TreatmentReport")
);
const PackageReport = lazy(() =>
  import("./components/Reports/Package/PackageReport")
);
const SubscriptionPlanReport = lazy(() =>
  import("./components/Reports/SubscriptionPlan/SubscriptionPlanReport")
);
const ProductsSoldReport = lazy(() =>
  import("./components/Reports/ProductsSold/ProductsSold")
);
const RankingClientsView = lazy(() =>
  import("./components/Reports/rankingClients/rankingClientsView")
);
const OpenModal = lazy(() =>
  import("./common/components/Modal/AlertPermissionModal")
);

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  "/login",
  "/register",
  "/registerone",
  "/recover",
  "/createpassword/:guid",
  "/lock",
  "/unsubscribe",
  "/notfound",
  "/error500",
  "/maintenance",
  "/paymentwizard/:guid",
  "/salespage/:guid",
  "/resetpassword",
];

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";
  if (
    listofPages.indexOf(location.pathname) > -1 ||
    currentKey == "createpassword" ||
    currentKey == "paymentwizard"
  ) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/register" component={waitFor(Register)} />
            <Route path="/registerone" component={waitFor(RegisterOne)} />
            <Route path="/recover" component={waitFor(Recover)} />
            <Route path="/createpassword/:guid" component={waitFor(Recover)} />
            <Route path="/lock" component={waitFor(Lock)} />
            <Route path="/unsubscribe" component={waitFor(Unsubscribe)} />
            <Route path="/notfound" component={waitFor(NotFound)} />
            <Route path="/error500" component={waitFor(Error500)} />
            <Route path="/maintenance" component={waitFor(Maintenance)} />
            <Route
              path="/paymentwizard/:guid"
              component={waitFor(PaymentWizard)}
            />
            <Route path="/salespage/:guid" component={waitFor(SalesPage)} />
            <Route path="/resetpassword" component={waitFor(ResetPassword)} />
            <Redirect to="/login" />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Router history={history}>
                  <Switch location={location}>
                    {/*Dashboard*/}
                    <PrivateRoute
                      path="/dashboardv1"
                      component={waitFor(DashboardV1)}
                    />
                    <PrivateRoute
                      path="/reports"
                      component={waitFor(Reports)}
                    />
                    <PrivateRoute
                      path="/absentClientReportPage"
                      component={waitFor(AbsentClientReportPage)}
                    />
                    <PrivateRoute
                      path="/birthReportPage"
                      component={waitFor(BirthReportPage)}
                    />
                    <Route
                      path="/dashboardv2"
                      component={waitFor(DashboardV2)}
                    />
                    <Route
                      path="/dashboardv3"
                      component={waitFor(DashboardV3)}
                    />

                    {/*Calender*/}
                    <Route path="/calendar" component={waitFor(Calendar)} />
                    <Route
                      path="/calendarDateNow/:date"
                      component={waitFor(Calendar)}
                    />

                    {/*Anamnesis*/}
                    <PrivateRoute
                      path="/anamnesisregistration"
                      component={waitFor(AnamnesisRegistration)}
                    />

                    {/*Sell Section*/}
                    <PrivateRoute
                      path="/sellpackage"
                      component={waitFor(SellPackage)}
                    />
                    <PrivateRoute
                      path="/sellproduct"
                      component={waitFor(SellProduct)}
                    />
                    <PrivateRoute
                      path="/sellSubscriptionPlan"
                      component={waitFor(SellSubscriptionPlan)}
                    />

                    {/*Treatment*/}
                    <PrivateRoute
                      path="/Treatment"
                      component={waitFor(Treatment)}
                    />
                    <PrivateRoute
                      path="/SearchTreatment"
                      component={waitFor(SearchTreatments)}
                    />
                    <PrivateRoute
                      path="/EditTreatment/:id"
                      component={waitFor(Treatment)}
                    />
                    <PrivateRoute
                      path="/SchedulingTreatment/:id"
                      component={waitFor(Treatment)}
                    />

                    {/*Receipt*/}
                    <PrivateRoute
                      path="/ReceiptIssuance"
                      component={waitFor(ReceiptIssuance)}
                    />

                    {/*Survey*/}
                    <PrivateRoute
                      path="/SurveySettings"
                      component={waitFor(SurveySettings)}
                    />
                    <PrivateRoute
                      path="/SurveyResults"
                      component={waitFor(SurveyResults)}
                    />

                    {/*Attested*/}
                    <PrivateRoute
                      path="/AttestedModel"
                      component={waitFor(AttestedModel)}
                    />
                    <PrivateRoute
                      path="/AttestedEmission"
                      component={waitFor(AttestedEmission)}
                    />

                    {/*Registrations*/}
                    <PrivateRoute
                      path="/OfficeRegister"
                      component={waitFor(OfficeRegister)}
                    />
                    <PrivateRoute
                      path="/ConsultCostumers"
                      component={waitFor(RedirectTabs)}
                    />
                    <PrivateRoute
                      path="/EditClientRegister/:id"
                      component={waitFor(RedirectTabs)}
                    />
                    <PrivateRoute
                      path="/ClientRegister"
                      component={waitFor(RedirectTabs)}
                    />
                    <PrivateRoute
                      path="/DoctorRegister"
                      component={waitFor(DoctorRegister)}
                    />
                    <PrivateRoute
                      path="/PackageRegister"
                      component={waitFor(PackageRegister)}
                    />
                    <PrivateRoute
                      path="/subscriptionPlanRegister"
                      component={waitFor(SubscriptionPlanRegister)}
                    />
                    <PrivateRoute
                      path="/HealthPlanRegister"
                      component={waitFor(HealthPlanRegister)}
                    />
                    <PrivateRoute
                      path="/EmployeeRegister"
                      component={waitFor(EmployeeRegister)}
                    />
                    <PrivateRoute
                      path="/ProfilesRegister"
                      component={waitFor(ProfilesRegister)}
                    />
                    <PrivateRoute
                      path="/CostCenterRegister"
                      component={waitFor(CostCenterRegister)}
                    />
                    <PrivateRoute
                      path="/UserRegister"
                      component={waitFor(UserRegister)}
                    />
                    <PrivateRoute
                      path="/ProcedureRegister"
                      component={waitFor(ProcedureRegister)}
                    />
                    <PrivateRoute
                      path="/GroupProcedureRegister"
                      component={waitFor(GroupProcedureRegister)}
                    />
                    <PrivateRoute
                      path="/ProductRegister"
                      component={waitFor(ProductRegister)}
                    />
                    <PrivateRoute
                      path="/ProviderRegister"
                      component={waitFor(ProviderRegister)}
                    />
                    <PrivateRoute
                      path="/MedicamentsRegister"
                      component={waitFor(MedicamentsRegister)}
                    />
                    <PrivateRoute
                      path="/RoomsRegister"
                      component={waitFor(RoomsRegister)}
                    />
                    <PrivateRoute
                      path="/RatingsRegister"
                      component={waitFor(RatingsRegister)}
                    />
                    <PrivateRoute
                      path="/FinancialAccountRegister"
                      component={waitFor(FinancialAccountRegister)}
                    />
                    <PrivateRoute
                      path="/PromotionRegister"
                      component={waitFor(PromotionRegister)}
                    />
                    <PrivateRoute
                      path="/ConfigurationsRegister"
                      component={waitFor(ConfigurationsRegister)}
                    />
                    <PrivateRoute
                      path="/SalesPageConfig"
                      component={waitFor(SalesPageConfig)}
                    />
                    <PrivateRoute
                      path="/linkBundler"
                      component={waitFor(LinkBundlerRegister)}
                    />
                    <PrivateRoute
                      path="/linkOfScheduling"
                      component={waitFor(LinkSchedulingRegister)}
                    />
                    <PrivateRoute
                      path="/gatewayOfPayments"
                      component={waitFor(GatewayOfPayments)}
                    />
                    <PrivateRoute
                      path="/covenantRegister"
                      component={waitFor(CovenantRegister)}
                    />
                    <PrivateRoute
                      path="/ConfigNFSe"
                      component={waitFor(ConfigNFSe)}
                    />
                    <PrivateRoute
                      path="/ProcedureHofToxineRegister"
                      component={waitFor(ProcedureHofToxineRegister)}
                    />
                    <PrivateRoute
                      path="/ProcedureHofRegister"
                      component={waitFor(ProcedureHofRegister)}
                    />
                    <PrivateRoute
                      path="/FormOfPaymentRegistration"
                      component={waitFor(FormOfPaymentRegistration)}
                    />

                    {/*Prescriptions*/}
                    <PrivateRoute
                      path="/PrescriptionsRegister"
                      component={waitFor(PrescriptionsRegister)}
                    />
                    <PrivateRoute
                      path="/EditPrescription/:id"
                      component={waitFor(PrescriptionsRegister)}
                    />

                    {/*Operations*/}
                    <PrivateRoute
                      path="/FinancialOperation"
                      component={waitFor(FinancialOperation)}
                    />
                    <PrivateRoute
                      path="/StockOperation"
                      component={waitFor(StockOperation)}
                    />
                    <PrivateRoute
                      path="/PreScheduling"
                      component={waitFor(PreScheduling)}
                    />
                    <PrivateRoute path="/nfse" component={waitFor(Nfse)} />
                    <PrivateRoute
                      path="/DadosNfse"
                      component={waitFor(DadosNfse)}
                    />
                    <PrivateRoute
                      path="/financialsummary"
                      component={waitFor(FinancialSummary)}
                    />
                    <PrivateRoute
                      path="/celcashfinancialtransaction"
                      component={waitFor(CelcashFinancialTransaction)}
                    />

                    {/*CustomerService*/}
                    <PrivateRoute
                      path="/centralatendimento"
                      component={waitFor(CentralAtendimento)}
                    />
                    <PrivateRoute
                      path="/perguntasfrequentes"
                      component={waitFor(PerguntasFrequentes)}
                    />
                    <PrivateRoute
                      path="/meuplano"
                      component={waitFor(MeuPlano)}
                    />

                    <PrivateRoute
                      path="/Icons"
                      component={waitFor(FontIcons)}
                    />

                    {/*Forms*/}
                    <Route
                      path="/form-standard"
                      component={waitFor(FormStandard)}
                    />
                    <Route
                      path="/form-extended"
                      component={waitFor(FormExtended)}
                    />
                    <Route
                      path="/form-validation"
                      component={waitFor(FormValidation)}
                    />
                    <Route
                      path="/form-wizard"
                      component={waitFor(FormWizard)}
                    />
                    <Route
                      path="/form-upload"
                      component={waitFor(FormUpload)}
                    />
                    <Route
                      path="/form-cropper"
                      component={waitFor(FormCropper)}
                    />

                    {/*Charts*/}
                    <Route path="/chart-flot" component={waitFor(ChartFlot)} />
                    <Route
                      path="/chart-radial"
                      component={waitFor(ChartRadial)}
                    />
                    <Route
                      path="/chart-chartjs"
                      component={waitFor(ChartChartJS)}
                    />
                    <Route
                      path="/chart-morris"
                      component={waitFor(ChartMorris)}
                    />
                    <Route
                      path="/chart-chartist"
                      component={waitFor(ChartChartist)}
                    />
                    <Route path="/open-modal" component={waitFor(OpenModal)} />
                    {/*Maps*/}
                    <PrivateRoute
                      path="/map-google"
                      component={waitFor(MapsGoogle)}
                    />
                    <PrivateRoute
                      path="/map-vector"
                      component={waitFor(MapsVector)}
                    />

                    {/*Reports*/}
                    <PrivateRoute
                      path="/consultationappointments"
                      component={waitFor(ConsultationAppointments)}
                    />
                    <PrivateRoute
                      path="/professionalfinances"
                      component={waitFor(ProfessionalFinances)}
                    />
                    <PrivateRoute
                      path="/subscriptionPlanComissionFinances"
                      component={waitFor(SubscriptionPlanComissionFinances)}
                    />
                    <PrivateRoute
                      path="/formOfPaymentsReport"
                      component={waitFor(FormOfPaymentsReport)}
                    />
                    <PrivateRoute
                      path="/reportfinancialaccount"
                      component={waitFor(Reportfinancialaccount)}
                    />
                    <PrivateRoute
                      path="/treatmentReport"
                      component={waitFor(TreatmentReport)}
                    />
                    <PrivateRoute
                      path="/packageReport"
                      component={waitFor(PackageReport)}
                    />
                    <PrivateRoute
                      path="/subscriptionPlanReport"
                      component={waitFor(SubscriptionPlanReport)}
                    />
                    <PrivateRoute
                      path="/productssold"
                      component={waitFor(ProductsSoldReport)}
                    />
                    <PrivateRoute
                      path="/rankingclients"
                      component={waitFor(RankingClientsView)}
                    />

                    {/*For Print*/}
                    {/* <PrivateRoute path="/printTreatment/:id" component={waitFor(TreatmentPrintable)} /> */}

                    <Redirect to="/login" />
                  </Switch>
                </Router>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
