import React, { useState } from 'react';
import { Async as AsyncSelect } from 'react-select';
import { URL_Clinic } from '../../../components/Services/clinicService';
import Axios from 'axios';

// Função para carregar opções de forma assíncrona
const loadOptions = async (inputValue) => {

    if (!inputValue || inputValue.length < 3) return [];
    try {
        // Fazendo a requisição usando Axios
        const response = await Axios.get(`${URL_Clinic}/GetByName/${inputValue}`)

        // Transformando os dados no formato necessário
        return response.data.map((item) => ({
            value: item.id,
            label: `ID:${item.id} - ${item.name}`,
        }));
    } catch (error) {
        console.error('Erro ao carregar as opções:', error);
        return [];
    }
};

const MyAsyncSelect = () => {
    const [selectedOption, setSelectedOption] = useState("")
    const handleChange = (selectedOption) => {
        let items = JSON.parse(localStorage.getItem('ClinicData'))
        items.id = selectedOption.value;
        localStorage.setItem("ClinicData", JSON.stringify(items))
        localStorage.setItem("selectedOption", JSON.stringify(selectedOption));
        window.location.reload();
    };

    return (
        <div style={{ width: '300px', margin: '0 auto' }}>
            <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadOptions}
                onChange={handleChange}
                placeholder="Selecione uma empresa..."
                value={{
                    value: JSON.parse(localStorage.getItem("selectedOption"))?.value,
                    label: JSON.parse(localStorage.getItem("selectedOption"))?.label
                }}

            />
        </div>
    );
};

export default MyAsyncSelect;