import axios from "axios";
import GenericEntity from "./GenericEntity";
import moment from "moment";

export class Filter {
    pageNumber = 1;
    rowCount = 0;
    pageSize = 10;
    finalDate = moment(new Date).toDate();
    date = moment(new Date).toDate();
    finalDateView = moment(new Date).format("YYYY-MM-DD");
    dateView = moment(new Date).format("YYYY-MM-DD");
    
    get currentPage() {
        return this.pageNumber
    }
    set currentPage(n) {
        this.pageNumber = n;
    }

    makeEmpty() {
        this.pageNumber = 1;
        this.rowCount = 0;
        this.pageSize = 10;
        this.finalDateView = undefined;
        this.dateView = undefined;
        this.date = undefined;
        this.finalDate = undefined;
        return this;
    }

    next() {
        return this.pageNumber++;
    }
}

/**
 * Use this class instead axios;
 */
export default class GenericCollection {

    url;
    constructor({ url }) {
        if (!url) throw Error("GenericCollection requires a URL parameter");
        this.url = url;
    }

    async get(filter) {
        let data;
        if (filter)
            data = (await axios.get(`${this.url}`, { params: filter })).data;
        else
            data = (await axios.get(`${this.url}`)).data;

        if (data == "") return [];

        let res = data.map(e => {
            let g = new GenericEntity(Object.assign({
                url: this.url
            }, e));
            return g;
        });

        return res;
    }

    async paged(filter) {
        return this.paged(filter, null);
    }

    async paged(filter, params) {
        return await this.pagedWithUrl(filter, params, `${this.url}/paged`);
    }

    async pagedWithUrl(filter, params, url) {
        let _fparams = {};
        if (params)
            _fparams = params;

        Object.assign(_fparams, filter);
        let { data } = await axios.get(`${url}`, {
            params: _fparams
        });
        filter.currentPage = data.currentPage;
        filter.rowCount = data.rowCount;
        filter.pageSize = data.pageSize;

        Object.assign(this, data);
        return data.results.map(e => new GenericEntity({
            ...e,
            url: this.url
        }));
    }
}
